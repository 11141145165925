import React from 'react'
import PropTypes from 'prop-types'
import IcoAutomation from '../img/icon-automation.png'
import IcoExtreme from '../img/icon-extreme.png'
import IcoWireless from '../img/icon-wireless.png'
import IcoMeditec from '../img/icon-meditec.png'


const Pricing = ({ data }) => (

  <div className="columns is-multiline">

    {data.map((price) => (
      
      <div key={price.plan} className="column is-6">
        <section className="section">
          <div>
            <div>
              {price.price === "automation" && 
                <div className="steute-colors-icon-container steute-colors-icon-automation">
                  <img src={IcoAutomation} alt="steute Automation"/>
                  <div className="has-text-weight-semibold">{price.plan}</div>
                </div>
              }
              {price.price === "extreme" &&
                <div className="steute-colors-icon-container steute-colors-icon-extreme">
                  <img src={IcoExtreme} alt="steute Extreme"/>
                  <div className="has-text-weight-semibold">{price.plan}</div>
                  </div>}
              {price.price === "wireless" &&
                <div className="steute-colors-icon-container steute-colors-icon-wireless">
                  <img src={IcoWireless} alt="steute Wireless"/>
                  <div className="has-text-weight-semibold">{price.plan}</div>
                </div>}
              {price.price === "meditec" &&
                <div className="steute-colors-icon-container steute-colors-icon-meditec">
                  <img src={IcoMeditec} alt="steute Meditec"/>
                  <div className="has-text-weight-semibold">{price.plan}</div>
                </div>}

            </div>
          </div>
          <p className="has-text-weight-semibold">{price.description}</p>
          <ul>
            {price.items.map((item) => (
              <li key={item} className="">
                {item}
              </li>
            ))}
          </ul>          
          <p className="">{price.extra}</p>
        </section>
      </div>
    ))}
  </div>
)

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default Pricing
