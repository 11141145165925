import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import BlogRoll from "../components/BlogRoll";
import Features from "../components/Features";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

import IcoBezpieczenstwo from "../img/safety.svg";
import IcoChemia from "../img/chemia.svg";
import IcoDeszcz from "../img/deszcz.svg";
import IcoDysza from "../img/dysza.svg";
import IcoEx from "../img/ex.svg";
import IcoHall from "../img/hall.svg";
import IcoHot from "../img/cieplo.svg";
import IcoZimno from "../img/zimno.svg";
import IcoMorze from "../img/morze.svg";
import IcoPotencjometr from "../img/potencjometr.svg";
import IcoRadio from "../img/wireless.svg";
import IcoMeditec from "../img/meditec.svg";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import MarkdownContent from "../components/MarkdownContent";
import Pricing from "../components/Pricing";

// typeof window !== "undefined" &&
//   window.gtag("event", "conversion", {
//     send_to: "AW-1068261550/ZPZ0CJPzjMUCEK7Bsf0D",
//     value: 1.0,
//     currency: "PLN",
//   });
// console.log("Dummy gtag do konwersji poszedł");

export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  main,
  mainextended,
  fullImageDivider,
  pricing,
}) => (
  <div>
    {/* qB custom jumbo */}
    <div className="jumbo-header jumbo-header-main shadow-line-lite">
      <div className="jumbo-slogan container columns">
        <div className="column is-10 is-offset-1">
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
            style={{
              color: "#404040",
              lineHeight: "1",
              padding: "0.25em",
            }}
          >
            {title}
          </h1>

          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              color: "#767171",
              lineHeight: "1",
              padding: "0.25em 0.25em 1em 0.75em",
            }}
          >
            {subheading}
          </h3>
          <Link className="btn-jumbo" to="/kontakt">
            ZAMÓW SZYBKĄ OFERTĘ
          </Link>
        </div>
      </div>
      {/*<div className="jumbo-product">
             <img src={productHeader} alt="Wyłącznik nożny steute"/>             
        </div>*/}
    </div>

    <section className="section section--gradient margin-top-0 padding-top-0">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <div className="has-text-justified is-size-4">
                      <MarkdownContent content={mainpitch.description} />
                    </div>
                  </div>
                </div>

                {/* qB 3 column start */}

                <div
                  className="full-width-image-section-title-container"
                  style={{
                    backgroundImage: `url(${
                      main.image1.image.childImageSharp
                        ? main.image1.image.childImageSharp.fluid.src
                        : main.image1.image
                    })`,
                  }}
                >
                  <h1 className="title has-text-centered">
                    ZAUFAJ NAM I ZAINWESTUJ W SPRAWDZONE ROZWIĄZANIA
                  </h1>
                  <div className="attention-content-down">
                    <div className="inner-circle">
                      <FontAwesomeIcon
                        className="is-size-3 is-centered-icon animate-flicker"
                        icon={faAngleDoubleDown}
                      />
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <section className="section">
                      <h3 className="has-text-centered has-text-weight-semibold">
                        Jak działamy
                      </h3>
                      <p className="has-text-justified">
                        Przedstaw nam szczegółowo swoje potrzeby, a my zajmiemy
                        się całą resztą. Wykorzystując naszą wiedzę i
                        doświadczenie, dobierzemy wyłącznik nożny najlepiej
                        pasujący do Twojej aplikacji i szybko przedstawimy
                        ofertę.
                      </p>
                    </section>
                  </div>
                  <div className="column">
                    <section className="section">
                      <h3 className="has-text-centered has-text-weight-semibold">
                        Na każde warunki
                      </h3>
                      <p className="has-text-justified">
                        Czy będzie to nabrzeże portowe, zakład produkcyjny,
                        strefa zagrożona wybuchem czy sala operacyjna – w naszej
                        ofercie znajdziesz wyłączniki i sterowniki nożne do w
                        pracy w każdych warunkach środowiskowych.
                      </p>
                    </section>
                  </div>
                  <div className="column">
                    <section className="section">
                      <h3 className="has-text-centered has-text-weight-semibold">
                        Wiele opcji
                      </h3>
                      <p className="has-text-justified">
                        Wyłączniki i sterowniki nożne mogą pełnić różne role.
                        Wyposażone w standardowe zestyki, potencjometry,
                        czujniki Halla, USB czy moduły radiowe sprawdzają się
                        nawet w bardzo wyrafinowanych układach sterowania.
                      </p>
                    </section>
                  </div>
                </div>
                <div className="divider-thick"></div>

                <div className="featured-icon-container columns is-mobile">
                  {/* <div className="full-width-image-section-multi-container featured-icon-container shadow-line-lite columns"></div> */}

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoEx} alt="Wersje Ex (ATEX)" />
                      <div>
                        Wersje
                        <br />
                        Ex (ATEX)
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img src={IcoZimno} alt="Niska temperatura" />
                      <div>
                        Niska
                        <br />
                        temperatura
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoHot} alt="Wysoka temperatura" />
                      <div>
                        Wysoka
                        <br />
                        temperatura
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img src={IcoDeszcz} alt="Wysoka wilgotność" />
                      <div>
                        Wysoka
                        <br />
                        wilgotność
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoChemia} alt="Agresywne środowisko" />
                      <div>
                        Agresywne
                        <br />
                        środowisko
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img src={IcoDysza} alt="Mycie ćiśnieniowe" />
                      <div>
                        Mycie
                        <br />
                        ciśnieniowe
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoMorze} alt="Środowisko morskie" />
                      <div>
                        Środowisko
                        <br />
                        morskie
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img
                        src={IcoPotencjometr}
                        alt="Precyzyjny potencjometr"
                      />
                      <div>
                        Precyzyjny
                        <br />
                        potencjometr
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoHall} alt="Wyjście analogowe" />
                      <div>
                        Wyjście
                        <br />
                        analogowe
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img src={IcoRadio} alt="Sterowanie radiowe" />
                      <div>
                        Sterowanie
                        <br />
                        radiowe
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="featured-ico">
                      <img src={IcoMeditec} alt="Technika medyczna" />
                      <div>
                        Technika
                        <br />
                        medyczna
                      </div>
                    </div>
                    <div className="featured-ico">
                      <img
                        src={IcoBezpieczenstwo}
                        alt="Systemy bezpieczeństwa"
                      />
                      <div>
                        Systemy
                        <br />
                        bezpieczeństwa
                      </div>
                    </div>
                  </div>
                </div>

                {/* qB 3 column end                                 */}

                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <MarkdownContent content={intro.description} />
                  </div>
                </div>
                <Features name="produkty" gridItems={intro.blurbs} />
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/produkty">
                      Dowiedz się więcej
                    </Link>
                  </div>
                </div>

                <div
                  className="full-width-image-container"
                  style={{
                    backgroundImage: `url(${
                      fullImageDivider.childImageSharp
                        ? fullImageDivider.childImageSharp.fluid.src
                        : fullImageDivider
                    })`,
                  }}
                />
                {/* <div className="full-width-divider shadow-line-lite"></div> */}
                <div className="columns">
                  <div className="column">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {mainextended.heading}
                    </h3>
                    <MarkdownContent content={mainextended.description} />
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-vertical">
                    <div className="tile">
                      <div className="tile is-parent is-vertical">
                        <article className="tile is-child">
                          <PreviewCompatibleImage
                            imageInfo={mainextended.image1}
                          />
                        </article>
                      </div>
                      <div className="tile is-parent">
                        <article className="tile is-child">
                          <PreviewCompatibleImage
                            imageInfo={mainextended.image2}
                          />
                        </article>
                      </div>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                        <PreviewCompatibleImage
                          imageInfo={mainextended.image3}
                        />
                      </article>
                    </div>
                  </div>
                </div>
                <div className="full-width-divider shadow-line-lite"></div>
                <h2 className="has-text-weight-semibold is-size-2">
                  {pricing.heading}
                </h2>
                <p className="is-size-5">{pricing.description}</p>
                <Pricing data={pricing.plans} />
                <div className="full-width-divider shadow-line-lite"></div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Baza wiedzy - ostatnie artykuły
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/baza-wiedzy">
                      Wszystkie artykuły
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  mainextended: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  fullImageDivider: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        mainextended={frontmatter.mainextended}
        fullImageDivider={frontmatter.full_image_divider_1}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            arrowlink
            linkgroup
          }
          heading
          description
        }
        main {
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            extra
            items
            plan
            price
          }
        }
        full_image_divider_1 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainextended {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
